.readButton {
  color: blue;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.005px;
  width: 100%;
  text-align: center;
  @media (min-width: 600px) {
    width: 150px;
    float: right;
    margin-right: 8px;
  }
}

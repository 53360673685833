.settingsCardContainer {
  background: #ffffff;
  padding-top: 8px;

  padding-bottom: 11.5px;
  margin-left: 20px;
  border-bottom: solid 1px #d0d6d9;
  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 600px) {
    margin-bottom: 0;
  }
  .title {
    font-size: 14px;
    line-height: 21px;
    color: #455b69;
    font-family: 'Heebo';
    font-weight: 400;
    cursor: auto;
    // border-radius: 40%;
    //TODO: check notification title
    width: 45%;
  }
  .childStyle {
    width: 50% !important;
  }

  .moreDetailsIcon {
    color: #5b707b;
    cursor: pointer;
    font-family: 'Heebo';
    font-style: normal;

    display: flex;
  }

  .cardTitleContainer {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
  }
}
.uniqueElement {
  border-bottom: solid 1px #d0d6d9 !important;
  margin-bottom: 20px;
}

.uniqueSettingGroup {
  display: flex;
  justify-content: space-between;

  width: 60%;
}

//Mobile layout
.cardTopSectionMobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 35px;
  margin-top: 18px;
}

.moreDetailsIconMobile {
  display: flex;
}

.titleMobile {
  display: flex;
  width: 65%;
}

.settingContainerMobile {
  display: flex;
  flex-direction: column;
}

$primary: #055aee;
$label-active: #153243;
$border-input: #8a99a1;
$subtitle: #73848e;
$error: #ff4d64;

$font-family-Heebo: Heebo, sans-sarif;

.currency-text-input {
  &__label {
    font-family: $font-family-Heebo;
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: 0.005em;
    text-align: left;
    color: $label-active;
    background-color: transparent;
    &.active {
      color: $primary;
    }

    &.invalid {
      color: $error !important;
    }
  }

  &__simbol-wrapper {
    font-family: $font-family-Heebo;
    font-size: 18px;
    font-weight: 400;
    color: $subtitle;
    padding-top: 0;
    padding-bottom: 0;
    border-color: $border-input;

    &.input-group-text {
      background-color: white;
    }

    &.invalid {
      border-color: $error !important;
      color: $error !important;
    }

    &.active {
      border-color: $primary;
      color: $primary;
    }

    &.disabled {
      background-color: #f5f6f8;
      border-color: #d0d6d9 !important;
    }

    &.with-left-icon {
      border-right: 0px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.with-right-icon {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
      border-right-width: 1px !important;
    }
  }

  &__input-amount {
    background-image: none !important;
    border-color: $border-input;
    padding-left: 12px;
    border-left-width: 1px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;

    font-family: $font-family-Heebo;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $label-active;

    &::placeholder {
      color: #a1adb4;
    }

    &.is-invalid {
      border-color: $error !important;
    }

    &.disabled {
      border-color: #d0d6d9 !important;
    }

    &.with-right-icon {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-right: none;
    }

    &.with-left-icon {
      padding-left: 0;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-left: none;
    }
  }

  .invalid-feedback {
    color: #ff4d64;
  }

  &__input-amount:focus {
    border-color: $primary;
    box-shadow: none !important;
  }
}

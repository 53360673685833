.autoCashoutContainer {
  display: flex;
  flex-direction: column;

  .dropdown {
    max-height: 45px;
  }

  .disabled {
    background: #e8ebec !important;
    border: 1px solid #8a99a1;
    border-radius: 4px;
    max-height: 45px;
    margin-top: 8px;
  }

  .error {
    color: #ff4d64;
  }

  small.error {
    display: block;
    margin-top: 4px;
  }

  .accountDropdown {
    min-width: 224px;

    &.empty {
      min-width: 240px;
    }
  }
}
.cashoutMinBalanceContainer {
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
}

.label {
  font-weight: 500;
}

.minBalanceInput {
  height: 45px;
}

.frequencyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 0;
  }
}

.timeContainer {
  width: 100%;

  @media (max-width: 559px) {
    margin-bottom: 20px;
    min-width: 150px;
    width: 100%;
  }
}

.frequencyDropdown {
  margin-right: 8px;
  width: 100%;

  @media (max-width: 559px) {
    margin-bottom: 20px;
    min-width: 150px;
  }

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.dropdownStyle {
  pointer-events: none;
  border: 1px solid #8a99a1;
  border-radius: 4px;
  cursor: pointer;
  max-height: 45px;
  margin-top: 8px;
}
.dropdownTextStyle {
  color: #73848e;
}

.dropdownIcon {
  position: absolute;
  top: 25%;
  right: 5%;

  height: 24px;
  width: 24px;
}

.cashoutCutoffMessage {
  margin-top: 8px;

  color: #f2994a;
  font-size: 12px;
  letter-spacing: 0.048px;
}

.cashOutTooltipStyle {
  width: 20px !important;
  height: 20px !important;
}

.tooltipContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

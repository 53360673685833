.inputWrapper {
  min-width: 150px;
  height: 45px;
  max-width: 100%;
  position: relative;
  margin-top: 8px;
}

.inputWrapper Input {
  background: none;
  border: 1px solid #8a99a1;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  max-height: 45px;
  padding: 12px;

  &::placeholder {
    color: #a1adb4;
  }

  &.invalid {
    border: 1px solid #ff4d64;
  }
}

.inputWrapper Button {
  color: #153243;
  font-family: 'Armin Grotesk';
  font-size: 14px;
  font-weight: 400;
  line-height: 12.359px;
  letter-spacing: 0.07px;
  border-radius: 5px !important;
  cursor: pointer;

  height: 80%;
  min-height: 80% !important;
  padding: 0px 7px;

  &:hover {
    background-color: white !important;
    font-weight: 600;
    color: #153243 !important;
  }
}

.inputWrapper Button.active {
  background-color: white;
  color: #153243;
  font-weight: 600;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #e9f1f2;

  position: absolute;
  aspect-ratio: 1;
  height: 80%;
  top: 10%;
  right: 1px;
  transform: translateX(-5%);

  border-radius: 5px !important;

  padding: 0 3px;
}
